<template>
  <div class="about">
    <h1>{{ $t("ABOUT.TITLE") }}</h1>
    <p>
    {{ $t("ABOUT.CONTENT_1") }}
    </p>
      <small>
        {{ $t("ABOUT.DONATE") }}
      </small>

      <!-- <p>
        <router-link title="Crypto" to="/donate">Crypto</router-link>
      </p>

      <EthCopy /> -->

      <form
        id="paypal_form"
        action="https://www.paypal.com/donate"
        method="post"
        target="_top">
          <input type="hidden" name="hosted_button_id" value="BQXFRCP36WCUL" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_BR/i/scr/pixel.gif" width="1" height="1" />
      </form>
    <p>
      <a href="mailto:cbevilaqua@raro.com.br?Subject=CristianoMinerals" target="_top">{{ $t("GLOBAL.CONTACT") }}</a>
    </p>
    <!-- <figure>
      <img src="/img/japa_fireworks.gif" alt="">
      <figcaption>{{ $t("GLOBAL.IMAGE_FROM") }} japan-fireworks.com</figcaption>
    </figure> -->
  </div>
</template>
<script>
  export default {
    name: "About",
  }
</script>

